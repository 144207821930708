<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { message, Modal } from 'ant-design-vue'
import {
  listServiceCenterRequesByPageUsingPost,
  listServicecenterRoleUsingPost
} from '@/services/onestop-bark/serviceCenterController'
import { useRouter } from 'vue-router'
const router = useRouter()
interface Service {
  id: number
  name: string
  description: string
  system: string
  url: string // 新增字段，用于存放跳转链接
  icon: string
}

const services = ref<Service[]>([])
const selectedService = ref<Service | null>(null)

// 用于数据获取
async function fetchServices() {
  try {
    const response = await listServicecenterRoleUsingPost({ servicecenterrole: '游客' })
    const records = response.data?.data?.records || []

    if (records) {
      services.value = records.map((record: any) => ({
        id: record.id,
        name: record.name,
        description: record.description,
        system: record.systemDescription,
        url: record.componentPath, // 假设每个记录新增了一个 'url' 属性作为跳转链接
        icon: record.icon || ''
      }))
    }

    console.log('获取到的服务:', services.value)
  } catch (error) {
    console.error('获取项目时出错:', error)
  }
}

onMounted(fetchServices)

// 修改为直接跳转到URL
function navigateToService(service: Service) {
  const url = service.url
  if (url.startsWith('http://') || url.startsWith('https://')) {
    window.open(url, '_blank') // 在新标签页中打开URL
  } else {
    router.push(url)  // 在当前页面打开URL // 在当前页面打开URL
  }
}
// 控制台输出服务列表，验证服务数据传递（仅作演示用途）
console.log(services.value.map((service) => ({
  id: service.id,
  name: service.name,
  description: service.description,
  system: service.system
})))
</script>

<template>
  <div class="service-grid">
    <div v-for="service in services" :key="service.id" class="service" @click="navigateToService(service)">
      <service-icon :icon="service.icon" />
      <h2>{{ service.name }}</h2>
      <p>{{ service.description }}</p>
      <p>系统: {{ service.system }}</p>
    </div>
  </div>
</template>

<style scoped src="@/components/Tabs/Departmen/Departmen.css"></style>