<template>
  <div class="oa-container">
    <div class="oa-left">
      <a-row>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-service-button1',
              activeTab === '老师服务' ? 'active tabs-extra-demo-service-button1' : ''
            ]"
            @click="activateTab('老师服务')"
          >老师服务
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-service-button2',
              activeTab === '学生服务' ? 'active tabs-extra-demo-service-button2' : ''
            ]"
            @click="activateTab('学生服务')"
          >学生服务
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-service-button3',
              activeTab === '游客服务' ? 'active tabs-extra-demo-service-button3' : ''
            ]"
            @click="activateTab('游客服务')"
          >游客服务
          </a-button>
        </a-col>
      </a-row>

      <a-tabs :active-key="activeTab" tab-position="top">
        <a-tab-pane v-for="(item, index) in tabs" :key="item.name" :tab="item.title">
          <div class="tab-content">
            <template v-if="!item.isEmpty">
              <div v-if="item.name === '老师服务'">
                <Teacher_Services></Teacher_Services>
                <div style="padding: 30px">
                  <router-link :to="{ name: 'Details', query: { tab: '老师' }}">
                    <button>查看更多</button>
                  </router-link>
                </div>
              </div>
              <div v-if="item.name === '学生服务'">
                <Student_Services></Student_Services>
                <div style="padding: 30px">
                  <router-link :to="{ name: 'Details', query: { tab: '学生' }}">
                    <button>查看更多</button>
                  </router-link>
                </div>
              </div>
              <div v-if="item.name === '游客服务'">
                <Visitor_Services></Visitor_Services>
                <div style="padding: 30px">
                  <router-link :to="{ name: 'Details', query: { tab: '游客' }}">
                    <button>查看更多</button>
                  </router-link>
                </div>
              </div>
            </template>
            <p v-else>暂无数据</p>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import Xs from '@/components/Tabs/xs.vue'
// import Liststudent from '@/components/Recommended_apps/Recommended_list/Student_list.vue'
import { UserOutlined } from '@ant-design/icons-vue'
import Student_Services from '@/components/Tabs/User_Services/Student_Services.vue'
import Visitor_Services from '@/components/Tabs/User_Services/Visitor_Services.vue'
import Teacher_Services from '@/components/Tabs/User_Services/Teacher_Services.vue'

interface TabItem {
  title: string
  isEmpty?: boolean
}

export default defineComponent({
  name: 'OAStatus',
  components: { Teacher_Services, Visitor_Services, Student_Services, UserOutlined,},
  data() {
    return {
      activeTab: '学生服务',
      tabs: [
        { title: '', isEmpty: false, name: '老师服务' },
        { title: '', isEmpty: false, name: '学生服务' },
        { title: '', isEmpty: false, name: '游客服务' }
      ]
    }
  },
  methods: {
    activateTab(title: string) {
      this.activeTab = title
    }
  }
})
</script>

<style scoped>
.oa-container {
  display: flex;
  background-color: #f0f2f5;
  padding: 16px;
  min-height: 500px;
  width: 100%;
}

.oa-left {
  flex-grow: 1;
  background-color: #fff;
  padding: 10px;
  min-width: 300px;
  border-radius: 8px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.tabs-extra-demo-service-button1,
.tabs-extra-demo-service-button2,
.tabs-extra-demo-service-button3 {
  width: 100%;
  text-align: left;
  font-size: 14px;
  border: none;
}

.tabs-extra-demo-service-button1 {
  background-color: #2edfa3;
}

.tabs-extra-demo-service-button1:hover,
.tabs-extra-demo-service-button1.active {
  box-shadow:
    0 -5px 0 0 #2edfa3,
    0 5px 0 0 #2edfa3;
}

.tabs-extra-demo-service-button2 {
  background-color: #23b7e5;
}

.tabs-extra-demo-service-button2:hover,
.tabs-extra-demo-service-button2.active {
  box-shadow:
    0 -5px 0 0 #23b7e5,
    0 5px 0 0 #23b7e5;
}

.tabs-extra-demo-service-button3 {
  background-color: #e36159;
}
.tabs-extra-demo-service-button3:hover,
.tabs-extra-demo-service-button3.active {
  box-shadow:
    0 -5px 0 0 #e36159,
    0 5px 0 0 #e36159;
}
</style>
