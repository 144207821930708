<template>
  <div class="footer">
    <!-- 底部内容容器 -->
    <div class="footer-content">
      <div class="column" style="flex: 1">
      <!-- 左侧栏，包含学校Logo -->
      <div class="left-section">
        <!-- 学校Logo图片 -->
        <img src="@/assets/logo.png" alt="桂林信息科技学院Logo" class="school-logo" />
      </div>
    </div>
      <div class="column" style="flex: 1">
        <!-- 中间栏，展示联系信息 -->
        <div class="center-section">
          <div class="contact-info" style="text-align: center">
            <!-- 地址信息 -->
            <!-- 邮编信息 -->
            <span style="display: block">邮箱：xxkjxy@guet. edu. cn</span>
            <span style="display: block">学工部：0773- 8100530</span>
            <span style="display: block">邮编: 541004</span>
            <span style="display: block">校址：桂林市临桂区岩图路9号（桂林信息科技学院）</span>
            <!-- 此处可追加电话、邮箱等其他联系方式 -->
          </div>
        </div>
      </div>
      <div class="column" style="flex: 1">
        <!-- 右侧栏，放置二维码 -->
        <div class="right-section">
          <!-- 二维码区域 -->
          <div
            class="qr-codes"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <!-- 第一个二维码及描述 -->
            <div style="margin-right: 20px; text-align: center">
              <img src="@/assets/weixin.png" alt="二维码1" />
              <span style="display: block; margin-top: 10px">关注我们</span>
            </div>
            <!-- 第二个二维码及描述 -->
            <div style="margin-right: 20px; text-align: center">
              <img src="@/assets/weixin.png" alt="二维码2" />
              <span style="display: block; margin-top: 10px">下载App</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright" style="text-align: center;background-color: #304583">
      <span>高校学生工作一站式服务平台 &copy; 2025 桂林信息科技学院·玖丨无极~开发团队版权所有。</span>
    </div>
  </div>

<!--  回到顶部-->
  <a-float-button-group shape="circle" :style="{ right: '24px' }">
    <a-back-top :visibility-height="0" />
  </a-float-button-group>

</template>

<style scoped>
.footer {
  width: 100%;
  height: 210px;
}
/* 底部内容样式 */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //max-width: 100%;
  height: 210px;
}

/* 列样式 */
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 左侧区域样式 */
.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 学校Logo样式 */
.school-logo {
  height: 80px; /* 设置Logo高度 */
  margin: 0 auto; /* 水平居中 */
  width: 320px;
}

/* 右侧区域样式，内容水平居中 */
.right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 二维码容器样式，内容水平居中 */
.qr-codes {
  display: flex;
  justify-content: center;
}

/* 二维码图片样式 */
.qr-codes img {
  height: 80px;
  width: 80px;
  margin-left: 10px; /* 图片间间距 */
}

/* 移除第一个二维码图片的左侧外边距 */
.qr-codes img:first-child {
  margin-left: 0;
}
</style>
<script setup lang="ts">
</script>
