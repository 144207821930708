<template>
  <div class="search-container">
    <!-- 标题 -->
    <h1>三网融合 · 一站服务</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SearchBar',
  setup() {
    const searchTerm = ref<string>('')
    const hotSearches = ref<string[]>(['财务', '学工系统', '采购审核', '辅导员', '采购'])

    const onSearch = (value: string) => {
      console.log('Search term:', value)
      // 可以在这里发起搜索请求或者跳转路由
    }

    const setSearchTerm = (term: string) => {
      searchTerm.value = term
    }

    return {
      searchTerm,
      hotSearches,
      onSearch,
      setSearchTerm
    }
  }
})
</script>

<style scoped>
.search-container {
  position: relative;
  text-align: center;
  padding: 50px;
  background-color: #f0f2f5; /* 设置备用背景色 */
  width: 100%;
  height: 300px;
  background-image: url('../../assets/xk.png');
  background-position: top center; /* 图片顶部居中 */
  background-size: contain; /* 图片完整显示 */

}

h1 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #333;
}

a-input-search {
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

.hot-search {
  margin-top: 20px;
}

.hot-search span {
  color: #333;
  margin-right: 10px;
}

.hot-search a-tag {
  cursor: pointer;
  margin-right: 10px;
}
</style>
