<template>
  <div class="oa-container">
    <div class="oa-left">
      <a-row>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-button1',
              activeTab === '热门服务' ? 'active tabs-extra-demo-button1' : ''
            ]"
            @click="activateTab('热门服务')"
            >热门服务
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-button2',
              activeTab === '推荐服务' ? 'active tabs-extra-demo-button2' : ''
            ]"
            @click="activateTab('推荐服务')"
            >推荐服务
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button
            :class="[
              'tabs-extra-demo-button3',
              activeTab === '最新服务' ? 'active tabs-extra-demo-button3' : ''
            ]"
            @click="activateTab('最新服务')"
            >最新服务
          </a-button>
        </a-col>
      </a-row>

      <a-tabs :active-key="activeTab" tab-position="top">
        <a-tab-pane v-for="item in tabs" :key="item.name" :tab="item.title">
          <div class="tab-content">
            <template v-if="!item.isEmpty">
              <div v-if="item.name === '推荐服务'">
                <Recommended_Services></Recommended_Services>
                <div style="padding: 1rem; text-align: center">
                  <router-link
                    :to="{ name: 'Details', query: { tab: '推荐' } }"
                    class="custom-more-link"
                  >
                    <button class="more-button">了解更多</button>
                  </router-link>
                </div>
              </div>
              <div v-if="item.name === '热门服务'">
                <Popular_Services></Popular_Services> 热门暂时停用，还没合适的热门算法和处理机制
                <!--                <Recommended_Services></Recommended_Services>-->
              </div>
              <div v-if="item.name === '最新服务'">
                <Latest_Services></Latest_Services>
                <div style="padding: 30px">
                  <router-link to="/Details">
                    <button>查看更多</button>
                  </router-link>
                </div>
              </div>
            </template>
            <p v-else>暂无数据</p>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import Xs from '@/components/Tabs/xs.vue'
// import Liststudent from '@/components/Recommended_apps/Recommended_list/Student_list.vue'
import { UserOutlined } from '@ant-design/icons-vue'
import Recommended_Services from '@/components/Tabs/Recommended_apps/Recommended_Services.vue'
import Popular_Services from '@/components/Tabs/Recommended_apps/Popular_Services.vue'
import Latest_Services from '@/components/Tabs/Recommended_apps/Latest_Services.vue'

interface TabItem {
  title: string
  isEmpty?: boolean
}

export default defineComponent({
  name: 'OAStatus',
  components: { Latest_Services, Popular_Services, Recommended_Services },
  data() {
    return {
      activeTab: '推荐服务',
      tabs: [
        { title: '', isEmpty: false, name: '热门服务' },
        { title: '', isEmpty: false, name: '推荐服务' },
        { title: '', isEmpty: false, name: '最新服务' }
      ]
    }
  },
  methods: {
    activateTab(title: string) {
      this.activeTab = title
    }
  }
})
</script>

<style scoped>
.oa-container {
  display: flex;
  background-color: #f0f2f5;
  padding: 16px;
  min-height: 500px;
  width: 100%;
}

.oa-left {
  flex-grow: 1;
  background-color: #fff;
  padding: 10px;
  min-width: 300px;
  border-radius: 8px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.tabs-extra-demo-button1,
.tabs-extra-demo-button2,
.tabs-extra-demo-button3 {
  width: 100%;
  text-align: left;
  font-size: 14px;
  border: none;
}

.tabs-extra-demo-button1 {
  background-color: #2edfa3;
}

.tabs-extra-demo-button1:hover,
.tabs-extra-demo-button1.active {
  box-shadow:
    0 -5px 0 0 #2edfa3,
    0 5px 0 0 #2edfa3;
}

.tabs-extra-demo-button2 {
  background-color: #23b7e5;
}

.tabs-extra-demo-button2:hover,
.tabs-extra-demo-button2.active {
  box-shadow:
    0 -5px 0 0 #23b7e5,
    0 5px 0 0 #23b7e5;
}

.tabs-extra-demo-button3 {
  background-color: #e36159;
}

.tabs-extra-demo-button3:hover,
.tabs-extra-demo-button3.active {
  box-shadow:
    0 -5px 0 0 #e36159,
    0 5px 0 0 #e36159;
}
</style>
