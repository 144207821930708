import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import admin from '@/views/admin.vue'
import { getLoginUserUsingGet } from '@/services/onestop-bark/userController'
import Department_management from '@/components/admin/Department_management.vue'
import not_found from '@/views/404.vue'
import Template from '@/views/TemplateComponent.vue'
import Service_Details from '@/components/admin/Service_Details.vue'
// 在你获取到res的逻辑之后
import { useUseridStore, useUserRoleStore } from '@/stores/user'
import Password_Reset from '@/views/Password_Reset.vue'
import JSONBig from 'json-bigint'
import Details from '@/views/Details.vue'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/admin',
      name: 'admin',
      component: admin,
    },
    {
      path: '/404',
      name: '404',
      component: not_found
    },
    {
      path: '/Template/:title',
      name: 'Template',
      component: Template,
      props: true, // 将路由参数传递给组件
    },
    {
      path: '/Service',
      name: 'Service',
      component: Service_Details,
    },
    {
      path: '/Password_Reset',
      name: 'Password_Reset',
      component: Password_Reset,
    },
    {
      path: '/Details',
      name: 'Details',
      component: Details,
    },
  ]
})




// 导航守卫
// 登录是 false
router.beforeEach(async (to, from, next) => {
  try {
    const res = await getLoginUserUsingGet()
    console.log('res', JSONBig.stringify(res.data.data?.id) ?? 'ID not found')
    console.log('!res.data', !res.data?.data)
    if (res.data?.data?.userRole) {
      const userRoleStore = useUserRoleStore()
      await userRoleStore.setUserRole(res.data.data.userRole)

      const useidStore = useUseridStore()
      await useidStore.setUserid(JSONBig.stringify(res.data.data.id))
    }
    if (!res.data?.data && to.path !== '/') {
      next({ path: '/' })
    } else if(to.path === '/' && res.data?.data){

     next({path:"/home"})
    }else {
      console.log('成功进入')

      next()
    }
  } catch (error) {
    console.error('Error fetching login status', error)
    next({ path: '/' })
  }
})

export default router
