<template>
  <!-- 使用 a-space 组件创建垂直间距布局，宽度占满屏幕 -->
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <!-- ants-design 的布局组件，包含头部、内容、底部 -->
    <a-layout>
      <!-- 定义头部样式并插入导航组件 -->
      <a-layout-header :style="headerStyle">
        <Mune_bule></Mune_bule>
      </a-layout-header>
      <!-- 定义内容区域样式 -->
      <a-layout-content :style="contentStyle">
        <div
          :style="{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
            margin: '0 auto'
          }"
        >
          <SearchBar></SearchBar>
          <User_Services></User_Services>

          <Recommended_apps></Recommended_apps>
          <Departmen></Departmen>
        </div>
      </a-layout-content>
      <!-- 定义底部样式并插入页脚组件 -->
      <a-layout-footer :style="footerStyle">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </a-space>
</template>

<script lang="ts" setup>
import { type CSSProperties, onMounted, ref } from 'vue'
// 导入自定义导航组件
import Mune_bule from '@/components/Navigation.vue'
// 导入自定义页脚组件
import Footer from '@/components/Footer.vue'
import Recommended_apps from '@/components/Tabs/Recommended_apps.vue'
import SearchBar from '@/components/Tabs/SearchBar.vue'
import Departmen from '@/components/Tabs/Departmen.vue'
import User_Services from '@/components/Tabs/User_Services.vue'
onMounted(() => {
  window.scrollTo(0, 0);
});
// 定义头部区域的样式
const headerStyle: CSSProperties = {
  position: 'sticky', // 添加粘性定位
  top: 0, // 始终置顶
  zIndex: 9999, // 确保始终是最上层
  textAlign: 'center', // 文本居中
  color: '#ffffff', // 文字颜色为白色
  height: '40px', // 高度为40px
  lineHeight: '40px', // 行高与高度一致，确保垂直居中
  background: '#87CEFA', // 渐变天蓝色背景
  padding: 0
}
// 定义内容区域的样式
const contentStyle: CSSProperties = {
  textAlign: 'center', // 文本居中
  // height: '1200px',
  color: '#1d8bcf', // 文字颜色为白色
  backgroundColor: '#eeeff1', // 背景颜色
  width: '100%',
  margin: '0 auto' //内容区域两边留空，居中
}
// 定义底部区域的样式
const footerStyle: CSSProperties = {
  // position: 'fixed', // 添加固定定位
  bottom: 0, // 固定在底部
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  textAlign: 'center',
  color: '#fff',
  height: '210px',
  backgroundColor: '#2c4c88'
}
</script>
