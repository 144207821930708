<template>
  <div style="height: 100vh;">
  <a-result status="404" title="404" sub-title="抱歉，您访问的页面不存在。">
    <template #extra>
      <a-button type="primary" @click="goBackHome">返回首页</a-button>
    </template>
  </a-result>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
const router = useRouter()
const goBackHome = () => {
  router.push('/')
}
</script>
<style scoped></style>
