<script lang="ts">
import { defineComponent, ref, computed, onMounted, type DefineComponent } from 'vue'
// 导入各个部门组件
import Student_Development from '@/components/Tabs/Departmen/Student_Development.vue'
import Student_Financial_Assistance from '@/components/Tabs/Departmen/Student_Financial_Assistance.vue'
import Student_Registration_Services from '@/components/Tabs/Departmen/Student_Registration_Services.vue'
import Comprehensive_Services from '@/components/Tabs/Departmen/Comprehensive_Services.vue'
import Caucus_service from '@/components/Tabs/Departmen/Caucus_service.vue'
import Employment_Services from '@/components/Tabs/Departmen/Employment_Services.vue'
import Information_Network_Services from '@/components/Tabs/Departmen/Information_Network_Services.vue'
import Logistics from '@/components/Tabs/Departmen/Logistics.vue'
import Armed_protection from '@/components/Tabs/Departmen/Armed_protection.vue'
import Activity_Room from '@/components/Tabs/Departmen/Activity_Room.vue'
// 导入服务中心标题列表接口
import { listServiceCenterTitleUsingPost } from '@/services/onestop-bark/serviceCenterController'
// 定义一个类型别名来表示所有组件名称的联合类型
type ComponentNames = '学生发展' | '学生资助' | '学籍服务' | '综合服务' | '党团服务' | '就业服务' | '信网服务' | '后勤服务' | '武装保卫' | '活动室';
// 创建一个映射，将服务名称映射到对应的组件
const componentsMap: {
  [K in ComponentNames]: DefineComponent<any, any, any, any, any, any, any, any, any, any, any, any>;
} = {
  学生发展: Student_Development,
  学生资助: Student_Financial_Assistance,
  学籍服务: Student_Registration_Services,
  综合服务: Comprehensive_Services,
  党团服务: Caucus_service,
  就业服务: Employment_Services,
  信网服务: Information_Network_Services,
  后勤服务: Logistics,
  武装保卫: Armed_protection,
  活动室: Activity_Room
};

export default defineComponent({
  name: 'OAStatus', // 组件名称
  components: {
    // 注册组件
    ...componentsMap
  },
  setup() {
    // 定义请求数据的变量
    const items = ref<API.ServiceCenterRequestTitle[]>([])
    // 初始化请求参数
    const requesttitle: API.ServiceCenterRequestTitle = { title: '' }

    // 异步函数，用于获取服务中心标题列表
    const fetchItems = async () => {
      try {
        const res = await listServiceCenterTitleUsingPost(requesttitle)
        // 更新数据
        items.value = res?.data?.data || []
      } catch (error) {
        // 打印错误信息
        console.error('获取项目时出错:', (error as any)?.response?.data?.message || [])
      }
    }

    // 使用 onMounted 钩子确保在组件挂载后才调用 fetchItems
    onMounted(() => {
      fetchItems()
    })

    // 计算属性，将数据项转换为包含组件的对象数组
    const itemsWithComponents = computed(() =>
      items.value.map((item) => ({
        title: item.title, // 标题
        hasData: item.title ? Boolean(item.title.padStart(0)) : false, // 修正padStart需要的参数
        component: item.title ? componentsMap[item.title as ComponentNames] : null // 对应的组件
      }))
    )

    // 返回需要在模板中使用的数据
    return { itemsWithComponents }
  }
})
</script>
<template>
  <div class="oa-container">
    <div class="oa-left">
      <a-tabs :default-active-key="1" tab-position="top" centered>
        <a-tab-pane v-for="item in itemsWithComponents" :key="item.title" :tab="item.title">
          <component :is="item.component" v-if="item.hasData" :open="true"></component>
          <p v-else>暂无数据</p>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<style scoped>
.oa-container {
  display: flex;
  background-color: #f0f2f5;
  padding: 16px;
  min-height: 500px;
  width: 100%;
}

.oa-left {
  flex-grow: 1;
  background-color: #fff;
  padding: 10px;
  min-width: 300px;
  border-radius: 8px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>
