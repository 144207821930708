// user.ts
import { defineStore } from 'pinia'

export const useUserRoleStore = defineStore('userRole', {
  state: () => ({
    role: null as string | null
  }),
  actions: {
    async setUserRole(role: string | null) {
      if (role !== null) {
        this.role = role
      }
    }
  }
})

export const useUseridStore = defineStore('userid', {
  state: () => ({
    id: null as string | null
  }),
  actions: {
    async setUserid(id: string | null) {
      if (id !== null) {
        this.id = id
      }
    }
  }
})

