<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import {
  addServicexiangxiUsingPost,
  deleteServicexiangxiUsingPost,
  listServicexiangxiByPageUsingPost,
  listServicexiangxiTitleUsingPost,
  updateServicexiangxiUsingPost
} from '@/services/onestop-bark/serviceXiangXiController'
import { type FormInstance, message } from 'ant-design-vue'
const total = ref<number>(0)
const requestParams = ref({
  current: 1,
  pageSize: 10,
  sortField: 'updateTime',
  sortOrder: 'desc'
})
interface Servicexiangxi {
  // Define the structure of Servicexiangxi here if not already defined
}

const data = ref<Servicexiangxi[]>([]) // Specify the correct type Servicexiangxi[]

const loading = ref(false)
const searchText = ref('') // 用于存储搜索关键词的ref
const fetchData = async () => {
  loading.value = true
  try {
    const response = await listServicexiangxiByPageUsingPost(requestParams.value)
    data.value = response.data.data?.records || [] // Ensure records is of type Servicexiangxi[]
    total.value = response.data.data?.total || 0
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}
onMounted(async () => {
  fetchData()
})
// 新增搜索方法
const onSearch = async (searchText: string) => {
  if (!searchText.trim()) {
    fetchData()
    return
  }

  try {
    const searchRequest: API.ServiceXiangXiTitleRequest = {
      title: searchText,
      // 注意：根据实际API需求调整请求体结构
      // 例如，可能还需要设置分页信息，如current和pageSize
      current: 1,
      pageSize: 4
    }

    const response = await listServicexiangxiTitleUsingPost(searchRequest)
    data.value = response.data.data?.records || []
    message.success('搜索成功')
    total.value = response.data.data?.total || 0
  } catch (error) {
    console.error('搜索服务详情时出错:', error)
    message.error('搜索失败')
  }
}
// 定义编辑模态框的状态
const editModalVisible = ref(false)
// 编辑中的记录
const editingRecord = ref({} as any)
// 表单实例
const editFormRef = ref<FormInstance>()
// 表单验证规则
const formRules = reactive({
  /* 定义验证规则 */
})

// 打开编辑模态框并填充数据
const openEditModal = (record: any) => {
  editingRecord.value = { ...record }
  editModalVisible.value = true
}

// 处理编辑模态框的确认提交
const handleEditModalOk = async () => {
  try {
    await editFormRef.value!.validate()
    await updateServicexiangxiUsingPost(editingRecord.value)
    message.success('服务详情更新成功')
    // 重置请求参数以便获取第一页数据
    // requestParams.value.current = 1
    // 重新获取列表数据

    console.log()
    editModalVisible.value = false // 关闭模态框
    fetchData()
  } catch (error) {
    console.error('更新服务详情时出错:', error)
    message.error('服务详情更新失败')
  }
}

// 修改 showEditModal 函数以打开编辑模态框
const showEditModal = async (record: any) => {
  openEditModal(record)
}

// 定义删除记录的函数
const deleteRecord = async (recordId: number) => {
  try {
    await deleteServicexiangxiUsingPost({ id: recordId })
    message.success('记录删除成功')
    // 删除后刷新表格数据
    await fetchData()
  } catch (error) {
    console.error('删除记录时出错:', error)
    message.error('记录删除失败')
  }
}

// 添加服务详情的函数
// 新增服务详情的记录模板
const newServiceDetail = ref({
  department: '',
  guide: '',
  location: '',
  service_days: '',
  service_hours: '',
  target_audience: '',
  title: '',
  url: ''
})

// 新增模态框的状态
const addModalVisible = ref(false)

// 表单实例
const addFormRef = ref<FormInstance>()

// 打开添加模态框
const showAddModal = () => {
  addModalVisible.value = true
}

// 处理添加模态框的确认提交
const handleAddModalOk = async () => {
  try {
    await addFormRef.value!.validate()
    // 确保传递newServiceDetail的值到addServicexiangxiUsingPost
    await addServicexiangxiUsingPost(newServiceDetail.value)
    message.success('服务详情添加成功')
    // 重置表单（使用笨方法逐个属性清空）
    newServiceDetail.value.department = ''
    newServiceDetail.value.guide = ''
    newServiceDetail.value.location = ''
    newServiceDetail.value.service_days = ''
    newServiceDetail.value.service_hours = ''
    newServiceDetail.value.target_audience = ''
    newServiceDetail.value.title = ''
    newServiceDetail.value.url = ''
    // 关闭模态框
    addModalVisible.value = false
    await fetchData()
  } catch (error) {
    console.error('添加服务详情时出错:', error)
    message.error('服务详情添加失败')
  }
}
</script>

<template>
  <div>
    <div style="width: 300px; padding: 15px; display: flex; align-items: center">
      <!-- 新增和服务详情操作放在同一行 -->
      <a-button type="primary" @click="showAddModal" style="margin-right: 8px"
        >新增服务详情</a-button
      >
      <a-input-search
        v-model:value="searchText"
        placeholder="输入搜索文本"
        enter-button
        @search="onSearch"
        style="flex-grow: 1; width: auto"
      />
    </div>
    <a-table
      class="auto-adjust-columns"
      :data-source="data"
      bordered
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1500, y: 600 }"
    >
<!--      <a-table-column title="ID" data-index="id" />-->
      <a-table-column title="名称" data-index="title" />
      <a-table-column title="所属部门" data-index="department" />
      <a-table-column title="服务人群" data-index="target_audience" />
      <a-table-column title="办理地点" data-index="location" />
      <a-table-column title="服务时间" data-index="service_hours" />
      <!-- 移除了重复列 -->
      <a-table-column title="服务日期" data-index="service_days" />
      <a-table-column title="办理入口" data-index="url" />
      <a-table-column title="办事指南" data-index="guide" :width="300">
        <template #default="{ record }">
          <div v-if="record.guide.length <= 100">{{ record.guide }}</div>
          <div v-else>{{ record.guide.slice(0, 100) }}...</div>
        </template>
      </a-table-column>
      <a-table-column title="操作" width="200">
        <template #default="{ record }">
          <a-space>
            <a-button type="primary" size="small" @click="showEditModal(record)"> 编辑</a-button>
            <!-- 修正参数传递 -->
            <a-popconfirm
              title="确定删除此记录吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="() => deleteRecord(record.id)"
            >
              <a-button danger size="small">删除记录</a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>

  <!-- 添加到 <template> 中 -->
  <a-modal v-model:visible="editModalVisible" title="编辑服务详情" @ok="handleEditModalOk" style="width:70%;">
    <a-form ref="editFormRef" :model="editingRecord" :rules="formRules" layout="vertical">
      <a-form-item label="标题" name="title">
        <a-input v-model:value="editingRecord.title" />
      </a-form-item>
      <a-form-item label="所属部门" name="department">
        <a-input v-model:value="editingRecord.department" />
      </a-form-item>
      <a-form-item label="服务人群" name="target_audience">
        <a-input v-model:value="editingRecord.target_audience" />
      </a-form-item>
      <a-form-item label="办理地点" name="location">
        <a-input v-model:value="editingRecord.location" />
      </a-form-item>
      <a-form-item label="服务时间" name="service_hours">
        <a-input v-model:value="editingRecord.service_hours" />
      </a-form-item>
      <a-form-item label="服务日期" name="service_days">
        <a-input v-model:value="editingRecord.service_days" />
      </a-form-item>
      <a-form-item label="办理入口" name="url">
        <a-input v-model:value="editingRecord.url" />
      </a-form-item>
      <a-form-item label="办事指南" name="guide">
        <TextEditor
          v-model="editingRecord.guide"
          api-key="no-api-key"
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          :init="{
            promotion: false,
            language: 'zh_CN',
            branding:false,
            selector: 'textarea.richTextBox',
            plugins: [
              'anchor',
              'autolink',
              'charmap',
              'codesample',
              'emoticons',
              'image',
              'link',
              'lists',
              'media',
              'searchreplace',
              'table',
              'visualblocks',
              'wordcount',
              'checklist',
              'mediaembed',
              'casechange',
              'export',
              'formatpainter',
              'pageembed',
              'a11ychecker',
              'tinymcespellchecker',
              'permanentpen',
              'powerpaste',
              'advtable',
              'advcode',
              'editimage',
              'advtemplate',
              'mentions',
              'tinycomments',
              'tableofcontents',
              'footnotes',
              'mergetags',
              'autocorrect',
              'typography',
              'inlinecss',
              'markdown',
              'importword',
              'exportword',
              'exportpdf'
            ],
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' }
            ]
          }"
          :rows="4"
          placeholder="请输入详细办事指南..."
          show-count
          :maxlength="500"
        />
      </a-form-item>
      <!-- 其他表单项，根据需要添加 -->
      <!-- 注意，此处省略了其他表单项以保持示例简洁 -->
    </a-form>
  </a-modal>

  <!-- 添加服务详情的模态框 -->
  <a-modal v-model:visible="addModalVisible" title="添加服务详情" @ok="handleAddModalOk" style="width:70%;">
    <a-form ref="addFormRef" :model="newServiceDetail" :rules="formRules" layout="vertical">
      <a-form-item label="标题" name="title">
        <a-input v-model:value="newServiceDetail.title" />
      </a-form-item>
      <a-form-item label="所属部门" name="department">
        <a-input v-model:value="newServiceDetail.department" />
      </a-form-item>
      <a-form-item label="服务人群" name="target_audience">
        <a-input v-model:value="newServiceDetail.target_audience" />
      </a-form-item>
      <a-form-item label="办理地点" name="location">
        <a-input v-model:value="newServiceDetail.location" />
      </a-form-item>
      <a-form-item label="服务时间" name="service_hours">
        <a-input v-model:value="newServiceDetail.service_hours" />
      </a-form-item>
      <a-form-item label="服务日期" name="service_days">
        <a-input v-model:value="newServiceDetail.service_days" />
      </a-form-item>
      <a-form-item label="办理入口" name="url">
        <a-input v-model:value="newServiceDetail.url" />
      </a-form-item>
      <a-form-item label="办事指南" name="guide">
        <TextEditor
          v-model="newServiceDetail.guide"
          api-key="no-api-key"
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          :init="{
            promotion: false,
            language: 'zh_CN',
            branding:false,
            selector: 'textarea.richTextBox',

            plugins: [
              'anchor',
              'autolink',
              'charmap',
              'codesample',
              'emoticons',
              'image',
              'link',
              'lists',
              'media',
              'searchreplace',
              'table',
              'visualblocks',
              'wordcount',
              'checklist',
              'mediaembed',
              'casechange',
              'export',
              'formatpainter',
              'pageembed',
              'a11ychecker',
              'tinymcespellchecker',
              'permanentpen',
              'powerpaste',
              'advtable',
              'advcode',
              'editimage',
              'advtemplate',
              'mentions',
              'tinycomments',
              'tableofcontents',
              'footnotes',
              'mergetags',
              'autocorrect',
              'typography',
              'inlinecss',
              'markdown',
              'importword',
              'exportword',
              'exportpdf'
            ],
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' }
            ]
          }"
          :rows="4"
          placeholder="请输入详细办事指南..."
          show-count
          :maxlength="500"
        />
      </a-form-item>
    </a-form> </a-modal
  ><!-- 自定义分页组件 -->
  <div style="text-align: right; margin-top: 16px">
    <a-pagination
      v-model:current="requestParams.current"
      v-model:pageSize="requestParams.pageSize"
      :total="total"
      @change="fetchData"
      @showSizeChange="fetchData"
    />
  </div>
</template>

<style scoped>
.auto-adjust-columns {
  .ant-table-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
  }
}
</style>
