// @ts-ignore
/* eslint-disable */
import request from '@/requestConfig';
import JSONBig from 'json-bigint' // 导入大数字处理库

/** addServiceCenter POST /api/serviceCenter/add */
export async function addServiceCenterUsingPost(
  body: API.ServiceCenterAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponselong>('/api/serviceCenter/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteServiceCenter POST /api/serviceCenter/delete */
export async function deleteServiceCenterUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/serviceCenter/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listServiceCenterByPage POST /api/serviceCenter/list/page */
export async function listServiceCenterByPageUsingPost(
  body: API.ServiceCenterRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServicecenter>('/api/serviceCenter/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSONBig.stringify(body),
    transformResponse: [(data) => JSONBig.parse(data)], // 处理大数字响应数据
    ...(options || {})
  })
}

/** listServiceCenterRequesByPage POST /api/serviceCenter/list/page/reques */
export async function listServiceCenterRequesByPageUsingPost(
  body: API.ServiceCenterRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServiceCenterRequest>('/api/serviceCenter/list/page/reques', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listServicecenterRole POST /api/serviceCenter/list/page/role */
export async function listServicecenterRoleUsingPost(
  body: API.ServiceCenterRoleRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServiceCenterRequest>('/api/serviceCenter/list/page/role', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listServiceCenterTitle POST /api/serviceCenter/list/title */
export async function listServiceCenterTitleUsingPost(
  body: API.ServiceCenterRequestTitle,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListServiceCenterRequestTitle>('/api/serviceCenter/list/title', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateServiceCenter POST /api/serviceCenter/update */
export async function updateServiceCenterUsingPost(
  body: API.ServiceCenterUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/serviceCenter/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
