// @ts-ignore
/* eslint-disable */
import request from '@/requestConfig'
import JSONBig from 'json-bigint' // 导入大数字处理库

/** addServicexiangxi POST /api/serviceCenterXiangXi/add */
export async function addServicexiangxiUsingPost(
  body: API.ServiceXiangXiAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponselong>('/api/serviceCenterXiangXi/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteServicexiangxi POST /api/serviceCenterXiangXi/delete */
export async function deleteServicexiangxiUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/serviceCenterXiangXi/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listServicexiangxiByPage POST /api/serviceCenterXiangXi/list/page */
export async function listServicexiangxiByPageUsingPost(
  body: API.ServiceXiangXiRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServicexiangxi>('/api/serviceCenterXiangXi/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSONBig.stringify(body),
    transformResponse: [(data) => JSONBig.parse(data)], // 处理大数字响应数据
    ...(options || {})
  })
}

/** listServicexiangxiRequesByPage POST /api/serviceCenterXiangXi/list/page/reques */
export async function listServicexiangxiRequesByPageUsingPost(
  body: API.ServiceXiangXiRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServiceXiangXiRequest>(
    '/api/serviceCenterXiangXi/list/page/reques',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSONBig.stringify(body),
      transformResponse: [(data) => JSONBig.parse(data)], // 处理大数字响应数据
      ...(options || {})
    }
  )
}

/** listServicexiangxiTitle POST /api/serviceCenterXiangXi/list/title */
export async function listServicexiangxiTitleUsingPost(
  body: API.ServiceXiangXiTitleRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageServiceXiangXiRequest>(
    '/api/serviceCenterXiangXi/list/title',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSONBig.stringify(body),
      transformResponse: [(data) => JSONBig.parse(data)], // 处理大数字响应数据
      ...(options || {})
    }
  )
}

/** updateServicexiangxi POST /api/serviceCenterXiangXi/update */
export async function updateServicexiangxiUsingPost(
  body: API.ServiceXiangXiUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/serviceCenterXiangXi/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
