<template>
  <a-row>
    <a-col :span="8">
      <a-breadcrumb class="navigation_right" separator="|" wrap="false">
        <a-breadcrumb-item><a @click="$router.push('/Home')">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>
          <a href="https://www.guit.edu.cn/" target="_blank">学校官网</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          ><a href="https://www.guit.edu.cn/xwzx/xyxw.htm">新闻网</a></a-breadcrumb-item
        >
      </a-breadcrumb>
    </a-col>
    <a-col :span="8">
      <div class="navigation_center">
        <a-input-search
          style="padding: 8px"
          size="small"
          placeholder="输入搜索关键词"
          enter-button
          @search="handleSearch"
        />
      </div>
    </a-col>

    <a-col :span="8">
      <a-breadcrumb class="navigation_left" separator="|" wrap="false">
        <a-breadcrumb-item v-if="role === 'admin'"
          ><a @click="$router.push('/admin')">后台入口</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item><a @click="jiaxuefen">点击加学分</a></a-breadcrumb-item>
        <a-breadcrumb-item><a @click="showPasswordModal">密码重置</a></a-breadcrumb-item>
        <a-breadcrumb-item><a @click="outUserLogin()">退出</a></a-breadcrumb-item>
      </a-breadcrumb>
    </a-col>
  </a-row>

  <teleport to="body">
    <a-modal
      v-model:visible="passwordModalVisible"
      title="更改密码"
      ok-text="保存"
      cancel-text="取消"
      @ok="handlePasswordSave"
    >
      <a-form :model="passwordForm" ref="passwordFormRef">
        <a-form-item
          label="旧密码"
          name="oldPassword"
          :rules="{ required: true, message: '旧密码不能为空' }"
        >
          <a-input v-model:value="passwordForm.oldPassword" type="password" />
        </a-form-item>
        <a-form-item
          label="新密码"
          name="userPassword"
          :rules="{ required: true, message: '新密码不能为空' }"
        >
          <a-input v-model:value="passwordForm.userPassword" type="password" />
        </a-form-item>
        <a-form-item
          label="确认新密码"
          name="confirmPassword"
          :rules="{ required: true, message: '确认新密码不能为空' }"
        >
          <a-input v-model:value="passwordForm.confirmPassword" type="password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </teleport>
</template>

<script lang="ts" setup>
import { updateMyUserUsingPost1, userLogoutUsingPost } from '@/services/onestop-bark/userController'
import router from '@/router'
import { message } from 'ant-design-vue'
import { useUserRoleStore } from '@/stores/user'
import { computed, reactive, ref } from 'vue'

const userRoleStore = useUserRoleStore()
const userRole = computed(() => userRoleStore.role)

const role = userRole.value

console.log(role)

async function outUserLogin() {
  try {
    const res = await userLogoutUsingPost()
    console.log('res', res.data)
    if (res.data.code === 0) {
      router.push({ path: '/' })
    } else {
      message.error(res.data.message)
    }
  } catch (error) {
    message.error('Logout failed')
    console.error(error)
  }
}
async function jiaxuefen() {
  message.success('做什么白日梦呢，你这孩子！')
}
// onMounted(() => {
//   const lastSearch = sessionStorage.getItem('lastSearch') || localStorage.getItem('lastSearch');
//   if (lastSearch) {
//     handleSearch(lastSearch as string);
//     // 可能还想设置为默认值，但请注意这会覆盖已有的默认值设置
//     // inputRef.value.defaultValue = lastSearch; // 如果有对输入框的ref
//   }
// });

const handleSearch = (value: string) => {
  if (value.trim()) {
    router.push({ name: 'Details', query: { search: value } })
  } else {
    // 可选：处理空搜索的情况，比如提示用户输入内容
    message.info('请输入搜索关键词')
  }
}
// 添加状态管理
const passwordModalVisible = ref(false)
const passwordForm = reactive({
  oldPassword: '',
  userPassword: '',
  confirmPassword: ''
})

// 显示密码重置对话框
const showPasswordModal = () => {
  passwordModalVisible.value = true
}
// 处理密码保存
const handlePasswordSave = async () => {
  // 确保新密码与确认密码匹配
  if (passwordForm.userPassword !== passwordForm.confirmPassword) {
    message.error('新密码和确认密码不一致')
    return
  }

  try {
    // 确保新密码长度不少于八位
    if (passwordForm.userPassword.length < 8) {
      message.error('新密码不能少于八位')
      return
    }
    // 调用API更新密码
    await updateMyUserUsingPost1(passwordForm)
    message.success('密码更改成功')
    passwordModalVisible.value = false
  } catch (error) {
    message.error('密码更改失败，请稍后重试')
  }
}
</script>
<style scoped>
.navigation_right {
  float: right;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.navigation_center {
  float: left;
  height: 100%;
  width: 200px;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation_left {
  float: left;
  height: 100%;
  line-height: 40px;
  text-align: center;
}
</style>
