<template>
  <div id="building">
    <div class="container">
      <div class="name_div1">
        <div class="name_left">
          <div class="name_left_div">
            <img alt="" src="../assets/logo.png" style="height: 60px" />
          </div>
          <div class="name_left_div1">
            <a-form
              :model="formState"
              name="basic"
              :label-col="labelCol"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
            >
              <a-form-item
                label="学 号"
                name="userAccount"
                :rules="[{ required: true, message: '请输入学号!' }]"
              >
                <a-input v-model:value="formState.userAccount" placeholder="请输入学号" />
              </a-form-item>

              <a-form-item
                label="密 码"
                name="userPassword"
                :rules="[{ required: true, message: '请输入密码!' }]"
              >
                <a-input-password v-model:value="formState.userPassword" placeholder="请输入密码" />
              </a-form-item>
              <!-- 在表单中合适的位置添加验证码显示和输入框 -->
              <a-form-item
                label="验证码"
                name="captcha"
                :rules="[{ required: false, message: '请输入验证码!' }]"
              >
                <span class="captcha-value">{{ captcha }}</span>
                <a-input v-model:value="userInputCaptcha" placeholder="请输入验证码" />
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 4, span: 16 }">
                <a-button type="primary" html-type="submit" style="width: 200px">登录</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div class="name_right">
          <img alt="" src="../assets/test.png" style="width: 100%" />

        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// 引入Vue的reactive函数来创建响应式数据
import { reactive, ref } from 'vue'
// 引入Ant Design Vue的消息提示组件
import { message } from 'ant-design-vue'
// 导入Vue Router用于页面跳转
import router from '@/router'
// 导入axios进行网络请求
import axios from 'axios'
import { userLoginUsingPost } from '@/services/onestop-bark/userController'
// 定义验证码状态和用户输入的验证码
const captcha = ref('')
const userInputCaptcha = ref('')
// 生成6位随机数字验证码的方法
function generateCaptcha() {
  let result = ''
  for (let i = 0; i < 6; i++) {
    result += Math.floor(Math.random() * 10)
  }
  captcha.value = result
  console.log(result)
}

// 页面加载时生成初始验证码
generateCaptcha()

// 设置axios允许携带cookie
axios.defaults.withCredentials = true

// 定义表单标签列的样式和跨度
const labelCol = { style: { width: '180px' }, span: 4 }

// 定义并初始化表单状态，类型为API.OneStopUserLoginRequest
const formState = reactive<API.UserLoginRequest>({
  userAccount: '', // 学号
  userPassword: '' // 密码
})

// 定义表单提交处理函数，参数为登录信息
const handleSubmit = async (values: API.UserLoginRequest) => {
  try {
    const res = await userLoginUsingPost(values)
    console.log('res', res.data)
    if (res.data.code === 0) {
      router.push({ path: '/home' })
      message.success('欢迎回来，用户')
    } else {
      message.error(res.data.message)
    }
    // console.log('res',res);
    // 发起登录请求
    // await request
    //   .post('/api/user/login', values)
    //   .then((res) => {
    //     // 如果返回码为0，说明登录成功，跳转到登录后的首页
    //     console.log('res',res);
    //     if (res.data.code === 0) {
    //       router.push({ path: '/Home' })
    //     } else {
    //       // 否则显示错误消息
    //       message.error(res.data.message)
    //     }
    //   })
    //   .catch((err) => {
    //     // 打印请求错误信息
    //     console.log(err)
    //   })
    // 如果请求过程中出现异常，会直接进入catch，这里可以省略
  } catch (error) {
    // 登录失败的默认提示信息
    const defaultLoginFailureMessage = '登录失败，请重试！'
    console.log(error)
    message.error(defaultLoginFailureMessage)
  }
}

// 表单提交成功的回调函数，异步处理表单提交逻辑
// onFinish 函数中添加验证码验证逻辑
const onFinish = async (values: any) => {
  if (userInputCaptcha.value === captcha.value) {
    await handleSubmit(values as API.UserLoginRequest)
  } else {
    message.error('验证码错误，请重新输入！')
  }
}

// 表单提交失败的回调函数，目前不做任何处理
const onFinishFailed = (errorInfo: any) => {}
</script>
<style>
/* 确保Ant Design的消息组件总是在最上层 */
.ant-message {
  z-index: 9999 !important; /* 或者选择一个足够大的z-index值以覆盖其他元素 */
}
.container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 让父容器充满视窗高度 */
}

.name_div1 {
  width: 720px;
  height: 430px;
  border: 15px solid rgba(73, 195, 230, 0.5); /* 透明边框 */
  border-radius: 15px; /* 添加圆角 */
}
.name_left {
  width: 65%;
  height: 100%;
  float: left;
  margin: auto;
}

.name_left_div {
  width: 100%;
  height: 15%;
  background: rgba(165, 44, 63);
  display: flex;
  justify-content: center;
  align-items: center;
}
.name_left_div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85%;
  background: rgba(165, 44, 63);
}
.name_right {
  display: flex;
  width: 35%;
  float: left;
  height: 100%;
}
#building {
  background: url('../assets/logo_bj.png');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}
</style>
