<script lang="ts" setup>
import Mune_bule from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import { computed, type CSSProperties, defineComponent, nextTick, onMounted, reactive, ref } from 'vue'
import { useUseridStore} from '@/stores/user'
const useridStore = useUseridStore()
const userid = computed(() => useridStore.id)
const id = userid.value
console.log(id)


const headerStyle: CSSProperties = {
  textAlign: 'center',
  color: '#ffffff',
  height: '40px',
  lineHeight: '40px',
  background: '#87CEFA',
  padding: 0
}

const contentStyle: CSSProperties = {
  textAlign: 'center',
  color: '#ffffff',
  height: '600px',
  lineHeight: '40px',
  background: '#a6c0cf',
  padding: 0
}
const footerStyle: CSSProperties = {
  bottom: 0,
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  textAlign: 'center',
  color: '#fff',
  height: '210px',
  backgroundColor: '#2c4c88'
}

</script>

<template>
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <a-layout>
      <a-layout-header :style="headerStyle">
        <Mune_bule />
      </a-layout-header>

      <a-layout-content :style="contentStyle">
        <div v-if="userid">
          字符串化后的用户ID: {{ userid }}
        </div>
      </a-layout-content>
      <a-layout-footer :style="footerStyle">
        <Footer />
      </a-layout-footer>
    </a-layout>
  </a-space>
</template>
<style scoped>

</style>
