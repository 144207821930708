import axios from 'axios';

// 错误处理方案： 错误类型
// enum ErrorShowType {
//   SILENT = 0,
//   WARN_MESSAGE = 1,
//   ERROR_MESSAGE = 2,
//   NOTIFICATION = 3,
//   REDIRECT = 9,
// }
// // 与后端约定的响应数据格式
// interface ResponseStructure {
//   success: boolean;
//   data: any;
//   errorCode?: number;
// }

// 创建 axios 实例
const request = axios.create({
  // baseURL: 'http://116.198.46.82:8101', // 设置基础 URL
  baseURL: '/api', // 设置基础 URL
  withCredentials:true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  // 请求拦截器
  // requestInterceptors: [
  //   (config: RequestOptions) => {
  //
  //     // const token = localStorage.getItem('token');
  //     // if (token) {
  //     //   config.headers['Authorization'] = `Bearer ${token}`; // 携带 token
  //     // }
  //     // 拦截请求配置，进行个性化处理。
  //     // const url = config?.url?.concat('?token = 123');
  //     // console.log();
  //     return config;
  //   },
  // ],
  //
  // // 响应拦截器
  // responseInterceptors: [
  //   (response) => {
  //     // 拦截响应数据，进行个性化处理
  //     const { data } = response as unknown as ResponseStructure;
  //     if (data?.success === false) {
  //       message.error('请求失败！');
  //     }
  //     return response.data;
  //   },
  // ],

});

// // 可以在这里添加请求拦截器和响应拦截器
request.interceptors.request.use(
  config => {
    // 添加授权 token 或其他自定义 headers
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default request;

