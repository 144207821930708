import './assets/main.css'
// import '/langs/zh_CN.js'
import { createApp } from 'vue'
// import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { createPinia } from 'pinia';
// import 'ant-design-vue/dist/antd.css';  // 引入 Ant Design Vue 样式
import request from './requestConfig' // 引入 axios 配置文件
// 导入Tinymce Vue组件
import Editor from '@tinymce/tinymce-vue';

// 创建 Vue 应用实例
const app = createApp(App)

// 将 axios 实例挂载到全局属性中，这样可以在组件中通过 this.$axios 访问
app.config.globalProperties.$axios = request
// 在Vue中注册Tinymce组件
app.component('TextEditor', Editor)

// 使用插件
// app.use(createPinia())
app.use(router)
app.use(Antd)
// 注册 Pinia
app.use(createPinia());
// 挂载应用
app.mount('#app')
