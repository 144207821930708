<script lang="ts" setup>
import Mune_bule from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import { listServicexiangxiTitleUsingPost } from '@/services/onestop-bark/serviceXiangXiController'
import { type CSSProperties, defineComponent, nextTick, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import Detailss from '@/components/Details/Detailss.vue'
import Recommended_Services from '@/components/Tabs/Recommended_apps/Recommended_Services.vue'

const headerStyle: CSSProperties = {
  textAlign: 'center',
  color: '#ffffff',
  height: '40px',
  lineHeight: '40px',
  background: '#87CEFA',
  padding: 0
}

const contentStyle: CSSProperties = {
  textAlign: 'center',
  color: '#1d8bcf',
  backgroundColor: '#eeeff1',
  width: '100%',
  margin: '0 auto'
}

const footerStyle: CSSProperties = {
  bottom: 0,
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  textAlign: 'center',
  color: '#fff',
  height: '210px',
  backgroundColor: '#2c4c88'
}


const route = useRoute()
// const activeKey = ref('全部');
const activeKey = ref(route.query.tab || '全部');
// if (route.query.name === '老师') {
//   activeKey.value = '老师';
// } else {
//   activeKey.value = route.query.name || '全部';
// }
// onMounted(() => {
//   const tabFromQuery = route.query.name as string | undefined
//   if (tabFromQuery === '老师') {
//     activeKey.value = '老师'
//   }
// })

watch(activeKey, (newKey) => {
  console.log(`tab="${newKey}"的值发生了变化`);
}, { immediate: true }); // immediate: true 会立即触发一次回调函数，打印初始值
const goBack = () => {
  window.history.back();
};
</script>

<template>
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <a-layout>
      <a-layout-header :style="headerStyle">
        <Mune_bule />
      </a-layout-header>

      <a-layout-content :style="contentStyle">
        <div class="service-wrapper">
          <div class="service-container">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="全部" tab="全部">
                <Detailss :servicecenterroles="''" :statuss="''" ></Detailss>
              </a-tab-pane>
              <a-tab-pane key="推荐" tab="推荐">
                <Detailss :servicecenterroles="''" :statuss="'1'"  ></Detailss>
              </a-tab-pane>
              <a-tab-pane key="学生" tab="学生">
                <Detailss :servicecenterroles="'学生'" :statuss="''"  ></Detailss>
              </a-tab-pane>
              <a-tab-pane key="老师" tab="老师">
                <Detailss :servicecenterroles="'老师'" :statuss="''"  ></Detailss>
              </a-tab-pane>
              <a-tab-pane key="游客" tab="游客">
                <Detailss :servicecenterroles="'游客'" :statuss="''"  ></Detailss>
              </a-tab-pane>

<!--              <template #leftExtra>-->
<!--                <a-button class="tabs-extra-demo-button">返回</a-button>-->
<!--              </template>-->
              <template #rightExtra>
                <a-button @click="goBack">返回</a-button>
              </template>
            </a-tabs>
          </div>
        </div>
      </a-layout-content>

<!--      <a-layout-footer :style="footerStyle">-->
<!--        <Footer />-->
<!--      </a-layout-footer>-->
    </a-layout>
  </a-space>
</template>
<style scoped>
.service-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px; /* 添加四角圆度 */
}
.header-bg .ant-descriptions-item-label {
  background-color: #2edfa3 !important;;
}

.service-container {
  width: 100%;
  border: 1px solid #d9d9d9;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px; /* 添加四角圆度 */
  background-color: #ffffff;
}
.tabs-extra-demo-button {
  margin-right: 16px;
}

.ant-row-rtl .tabs-extra-demo-button {
  margin-right: 0;
  margin-left: 16px;
}
</style>
