<script lang="ts" setup>
import Mune_bule from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import { listServicexiangxiTitleUsingPost } from '@/services/onestop-bark/serviceXiangXiController'
import { type CSSProperties, defineComponent, nextTick, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'

const headerStyle: CSSProperties = {
  textAlign: 'center',
  color: '#ffffff',
  height: '40px',
  lineHeight: '40px',
  background: '#87CEFA',
  padding: 0
}

const contentStyle: CSSProperties = {
  color: '#1d8bcf',
  backgroundColor: '#eeeff1',
  width: '100%',
  margin: '0 auto'
}

const footerStyle: CSSProperties = {
  bottom: 0,
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  textAlign: 'center',
  color: '#fff',
  height: '210px',
  backgroundColor: '#2c4c88'
}
const route = useRoute()
const serviceDetails = ref<{ [key: string]: any } | null>(null)
const serviceData = reactive({
  title: '',
  info: [
    { label: '所属部门', value: '' },
    { label: '服务人群', value: '' },
    { label: '办理地点', value: '' },
    { label: '服务时间', value: '' },
    { label: '服务日期', value: '' }
  ] as Array<{ label: string; value: string | undefined; span?: string }>,
  guide: [] as string | string[] // Adjusted type to allow string or string array
})

onMounted(async () => {
  // 使用nextTick确保在DOM更新后再执行滚动重置
  await nextTick();
  window.scrollTo(0, 0);
  try {
    const response = await listServicexiangxiTitleUsingPost({ title: route.params.title as string })
    const records = response.data.data?.records
    if (records && records.length > 0) {
      const firstRecord = records[0]
      serviceDetails.value = firstRecord
      serviceData.title = firstRecord.title ?? ''
      serviceData.info = [
        { label: '所属部门', value: firstRecord.department ?? '' },
        { label: '服务人群', value: firstRecord.target_audience ?? '' },
        { label: '办理地点', value: firstRecord.location ?? '' },
        { label: '服务时间', value: firstRecord.service_hours ?? '' },
        { label: '服务日期', value: firstRecord.service_days ?? '' }
      ] as Array<{ label: string; value: string | undefined; span?: string }>
      serviceData.guide = firstRecord.guide ?? [] // Simplified assignment to default to an empty array
      console.log('服务详情已更新:', firstRecord.title)
    } else {
      console.warn('No record found for the given title.')
    }
  } catch (error) {
    console.error('Error fetching service details:', error)
  }

})

const handleJump = () => {
  if (serviceDetails.value?.url) {
    const url = serviceDetails.value.url
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank') // 在新标签页中打开URL
    } else {
      router.push(url) // 在当前页面打开URL
    }
  } else {
    console.warn('Service URL is missing.')
  }
}
</script>

<template>
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <a-layout>
      <a-layout-header :style="headerStyle">
        <Mune_bule />
      </a-layout-header>

      <a-layout-content :style="contentStyle">
        <div class="service-wrapper">
          <div class="service-container">
            <a-descriptions
              :title="`${serviceData.title}-办事指南`"
              layout="vertical"
              bordered
              class="title-centered"
            >
<!--                   :title="`${serviceData.title} - 办事指南详情`"-->
              <a-descriptions-item label="" :span="24"  class="header-bg" >
                <div
                  v-html="serviceData.guide"
                  style="white-space: pre-wrap; height: 600px; overflow-y: auto"
                ></div>
              </a-descriptions-item>
              <a-descriptions-item
                v-for="item in serviceData.info"
                :key="item.label"
                :label="item.label"
                :labelStyle="{ color: '#010202', fontWeight: 'bold' }"
              >
                <strong style="color: #1d8bcf">

                  {{ item.value }}
                </strong>
              </a-descriptions-item>
              <a-descriptions-item
                label="线上办理入口"
                :span="3"
                :labelStyle="{ color: '#010202', fontWeight: 'bold' }"
              >

                <a-button type="primary" @click="handleJump">立即办理</a-button>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
      </a-layout-content>

<!--      <a-layout-footer :style="footerStyle">-->
<!--        <Footer />-->
<!--      </a-layout-footer>-->
    </a-layout>
  </a-space>
</template>
<style scoped>
.service-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px; /* 添加四角圆度 */
}
.header-bg .ant-descriptions-item-label {
  background-color: #2edfa3 !important;;
}

.service-container {
  width: 100%;
  border: 1px solid #d9d9d9;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px; /* 添加四角圆度 */
}

</style>
